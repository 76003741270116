import React, {useRef, useState, useEffect} from 'react'
import {gsap, ScrollTrigger} from 'gsap/all'
import {useMediaQuery} from '@react-hook/media-query'

import Observe from '../../elements/Observe'

gsap.registerPlugin(ScrollTrigger)

const Hero = ({onClickNext, scrollHeight, setScrollHeight, ...props}) => {
	const [videoState, setVideoState] = useState(false)
	const [videoLoaded, setVideoLoaded] = useState(false)
	const [mobileVideo, setMobileVideo] = useState(false)
	const [heroUp, setHeroUp] = useState(false)
	const [videoThumbnail, setVideoThumbnail] = useState(true)
	const mediaBreakpoint = useMediaQuery('(min-width: 1024px)')
	const videoRef = useRef(null)
	const heroVideo = useRef(null)

	const handleObserverChange = intercepted => {
		if (!intercepted) {
			setVideoLoaded(false)
			setVideoState(false)
		} else {
			setVideoState(true)
		}
	}

	useEffect(() => {
		if (!mediaBreakpoint) {
			setMobileVideo(true)
		} else {
			setMobileVideo(false)
		}
	}, [mobileVideo])

	useEffect(() => {
		if (videoState && !heroUp) {
			videoRef?.current?.play()
		} else if (!videoState) {
			if (videoRef?.current) {
				videoRef.current.currentTime = 0
				setVideoLoaded(false)
			}
		}
	}, [heroUp, videoState])

	useEffect(() => {
		gsap.timeline({
			scrollTrigger: {
				trigger: heroVideo.current,
				start: 'top top',
				toggleActions: 'play none none reverse',
				scrub: true,
				onEnter: () => {
					setHeroUp(true)
				},
				onLeaveBack: () => {
					setHeroUp(false)
				},
				// onEnterBack: () => {
				// 	setHeroUp(false)
				// }
			},
		})
	}, [])

	useEffect(() => {
		const initialScrollPos = window.scrollY
		let isTrackpad = false;
		
		const heroUpMob = (event) => {
			event.preventDefault()
			setHeroUp(true)
			if (heroUp) {
				setTimeout(() => {
					window.removeEventListener('touchmove', heroUpMob)
				},500)
			}
		}
		
		const heroUpFn = (event) => {
			event.preventDefault()
			if (event.deltaY > 0) {
				setHeroUp(true)
				if (event.wheelDeltaY) {
					if (Math.abs(event.wheelDeltaY) !== 120) {
					  isTrackpad = true;
					}
				} else if (event.deltaMode === 0) {
					isTrackpad = true;
				}

				if (heroUp) {
					setTimeout(() => {
						window.removeEventListener('wheel', heroUpFn)
					}, isTrackpad ? 1500 : 500)
				}

			} else if (initialScrollPos <= 1 && event.deltaY < 0) {
				setHeroUp(false)
				window.addEventListener('wheel', heroUpFn, { passive: false })
			}
		}
		const eventController = (event) => {
			if (window.scrollY === 0 && heroUp && event.deltaY < 0) {
				window.addEventListener('wheel', heroUpFn, { passive: false })
			}
			if (window.innerWidth < 1024 && window.scrollY === 0 && heroUp && event.deltaY < 0) {
				window.addEventListener('touchmove', heroUpMob,{ passive: false })
			}
		}
		
		if (window.innerWidth > 1024) {
			window.addEventListener('wheel', heroUpFn, { passive: false })
			window.addEventListener('wheel', eventController, { passive: false })
		} else {
			window.addEventListener('touchmove', heroUpMob,{ passive: false })
		}

		return () => {
			if (window.innerWidth > 1024) {
				window.removeEventListener('wheel', heroUpFn)
			} else {
				window.removeEventListener('touchmove', heroUpMob)
			}
			clearTimeout()
		}
	}, [heroUp])

	useEffect(() => {
		const timeUpdate = () => {
			if (!heroUp) {
				videoRef?.current?.addEventListener('timeupdate', () => {
					if (
						videoRef?.current?.currentTime >= 2.102259 &&
						videoRef?.current?.currentTime <= 2.637122
					) {
						setVideoLoaded(true)
					}
				})
			}
		}
		timeUpdate()
		return () => timeUpdate
	}, [videoRef, heroUp])

	return (
		<section
			{...props}
			ref={heroVideo}
			className={`hero__slide${heroUp ? ' slide-up' : ''}`}
		>
			<Observe
				onChange={handleObserverChange}
				treshold={[0, 0, 25, 0, 5, 0, 75, 1]}
			>
				{videoThumbnail && <picture className="hero__slide-thumbnail">
					<source media="(max-width: 1023.8px)" srcSet="/videos/home/intro-mobile-poster.png"/>
					<source media="(min-width: 1024px)" srcSet="/videos/home/intro-desktop-poster.png"/>
					<img src="/videos/home/intro-desktop-poster.png" alt=""/>
					</picture>
				}
				<video
					autoPlay
					playsInline
					width="100%"
					height="100%"
					muted
					className="show-mob"
					ref={videoRef}
					onLoadedData={() => setVideoThumbnail(false)}
				>
					<source src={'https://player.vimeo.com/progressive_redirect/playback/778004688/rendition/1080p/file.mp4?loc=external&signature=7dc4778361ff1df81e635b9725345d95c5febcb50ecdd4c95f56416b4ee56180'} type={'video/mp4'}/>
				</video>
				<video
					autoPlay
					playsInline
					width="100%"
					height="100%"
					muted
					className="show-desk"
					ref={videoRef}
					onLoadedData={() => setVideoThumbnail(false)}
				>
					<source src={'https://player.vimeo.com/progressive_redirect/playback/777265082/rendition/1080p/file.mp4?loc=external&signature=f54617de876ec576ea3331dd45c036c67d195ae291e2614983b8bca0fa864819'} type={'video/mp4'}/>
				</video>
			</Observe>
			<div className={`scroll-down  ${videoLoaded ? 'show-arrows' : ''}`}>
				<img
					src="/svgs/finger-down.svg"
					alt="finger"
					className="finger"
					onClick={() => {
						setHeroUp(true)
					}}
				/>
			</div>
		</section>
	)
}

export default Hero

import React from 'react'

import Image from '../../elements/Image'
import Observe from '../../elements/Observe';
import CapsUnderline from '../../elements/CapsUnderline'

import PartnersLogos from '../../../jsons/home/partners.json'

const Partners = () => {
    return (
        <Observe className="partners">
            <div className="partners__info">
                <h4>OUR FEATURED CLIENTS</h4>
                <div>
                    <CapsUnderline color="pink" lineType="bottom">Amazing Partners</CapsUnderline>
                    <p>
                        We love what we do. Working with passionate founders, decision makers, and dreamers to transform
                        visions into tangible realities brings us joy.
                        Our clients are our why. We are initially industry agnostic and view each creative brief as
                        another chance to tackle a unique challenge and provide creative solutions by combining your
                        team and ours under one roof.
                    </p>
                </div>
            </div>
            <div className="partners__logos">
                {PartnersLogos.map((item, index) => {
                    return (
                        <Image
                            key={`image-${index}`}
                            src={item.image}
                            alt="logo"
                            className={'client-logo'}
                            width={item.width}
                            height={item.height}
                        />
                    )
                })}
            </div>
        </Observe>
    )
}

export default Partners

import React from 'react'

const CapsUnderline = props => {
    const {
        color,
        children,
        titleType,
        lineType,
        linePosition
    } = props

    const classNames = () => {
        return `line line-${lineType} ${color} ${linePosition}`
    }

    return (
        <>
            {titleType === 'heading' ? (
                <h1 className="relative">{children} <div className={classNames()}></div></h1>
            ) : (
                <h3 className="relative">{children}<div className={classNames()}></div></h3>
            )}
        </>
    )
}

export default CapsUnderline

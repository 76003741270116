import React from 'react'
import Observe from '../../elements/Observe'
import Marquee from 'react-fast-marquee'
import SafariDetect from '../../elements/SafariDetect'

const OurYourHouse = () => {
    return (
        <SafariDetect>
            <Observe className="our-your-house dark-section" id="our-your-house">
                <h3 className="relative">
                    The House Of is a creative agency built to be your brand’s home - the one place you can be the most
                    honest. We are here to understand your entire vision. Then we craft stories to evoke emotions that
                    create lasting memories and brand loyalty.
                </h3>
                <h3 className="scrolling-text">
                    <Marquee speed={150} gradient={false}>
                        <span>Our House Is Your House</span>
                        <span>Our House Is Your House</span>
                        <span>Our House Is Your House</span>
                    </Marquee>
                </h3>
            </Observe>
        </SafariDetect>
    )
}

export default OurYourHouse

import React from 'react'

const HouseVideo = () => {
	return (
		<div id="enter-home-wrapper" className="enter-our-home__wrapper">
			<video
				autoPlay
				loop
				playsInline
				width="100%"
				height="100%"
				muted
				className="show-mob"
			>
				<source src={'https://player.vimeo.com/progressive_redirect/playback/767182665/rendition/1080p/file.mp4?loc=external&signature=da79aba52d293f7545de97b13cc32ae77727a2ee1950ebfbf87f73811d9d83d5'} type={'video/mp4'}/>
			</video>
			<video
				autoPlay
				loop
				playsInline
				width="100%"
				height="100%"
				id="enter-our-home__video"
				muted
				className="show-desk"
			>
				<source src={'https://player.vimeo.com/progressive_redirect/playback/777272417/rendition/1080p/file.mp4?loc=external&signature=b1e8835585d9e3a005446d4bc3633b58d51b9c2b25be83367471d4d1201e9b40'} type={'video/mp4'}/>
			</video>
		</div>
	)
}

export default HouseVideo

import React from 'react'
import Link from 'gatsby-link'

import Image from '../../elements/Image'
import Video from '../../elements/Video'
import Observe from '../../elements/Observe'

import WorkShowcaseData from '../../../jsons/home/work-showcase.json'
import CapsUnderline from '../../elements/CapsUnderline'


const WorkShowcase = () => {
    return (
        <section className="work-showcase">
            <Observe>
                <CapsUnderline color="pink" lineType="bottom">Latest Projects</CapsUnderline>
                <div className="portfolio-content-container">
                    <div className="portfolio-wrapper top">
                        {WorkShowcaseData.showcaseFirstData.map((item, index) => {
                            return (
                                <Link to={`/${item.link}`} key={`first-cards-${index}`} className="portfolio-container">
                                    <div className="portfolio_info-media">
                                        {item.type === 'video' ? (
                                            <Video autoPlay={true}
                                                   loop={true}
                                                   src={item.src}
                                                   type="mp4"
                                            />
                                        ) : (
                                            <Image
                                                src={item.src}
                                                alt={item.projectName}
                                            />
                                        )}
                                    </div>
                                    <div className="portfolio_info-wrapper">
                                        <div className="portfolio_info">
                                            <p>{item.projectName}</p>
                                            <h3>&ldquo;{item.projectDescription}&rdquo;</h3>
                                            <p></p>
                                        </div>
                                        <div className="portfolio_info-visit">
                                            <h4>VIEW PROJECT</h4>
                                        </div>
                                    </div>
                                </Link>
                            )
                        })}
                    </div>
                    <div className="portfolio-wrapper">
                        {WorkShowcaseData.showcaseSecondData.map((item, index) => {
                            return (
                                <Link to={`${item.link || '#'}`} key={`small-cards-${index}`}
                                      className={`portfolio-container${item.type === 'video' && !item.projectName ? ' portfolio-container-video' : ''}`}>
                                    <div className="portfolio_info-media">
                                        {item.type === 'video' ? (
                                            <Video autoPlay={true}
                                                   loop={true}
                                                   src={item.src}
                                                   type="mp4"
                                            />
                                        ) : (
                                            <Image
                                                src={item.src}
                                                alt={item.projectName}
                                            />
                                        )}
                                    </div>
                                    {item.projectName &&
                                        <div className="portfolio_info-wrapper">
                                            <div className="portfolio_info">
                                                <p>{item.projectName}</p>
                                                <h3>&ldquo;{item.projectDescription}&rdquo;</h3>
                                                <p></p>
                                            </div>
                                            <div className="portfolio_info-visit">
                                                <h5>{item.projectName}</h5>
                                                <h4>VIEW PROJECT</h4>
                                            </div>
                                        </div>
                                    }
                                </Link>
                            )
                        })}
                    </div>
                </div>
            </Observe>
        </section>
    )
}

export default WorkShowcase
